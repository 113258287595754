<script setup lang="ts">
  import { useField, useForm } from 'vee-validate'
  import * as zod from 'zod'
  import { useStoreReferral } from '~/stores/tests/useStoreReferral'
  import type { GenericApiError } from '~/types/apiError'
  import type { ButtonBranding } from '~/types/branding'

  const SectionName = 'FU-Referral'
  const { companyId } = useRoute().params
  const { data: creator } = useStoreCreator().fetchCreator()

  defineProps<{
    branding: ButtonBranding
  }>()

  const emit = defineEmits<{
    success: []
  }>()

  const { t, te } = useI18n()

  const validationSchema = toTypedSchema(
    zod.object({
      email: zod
        .string({ required_error: t('Modal.Register.errors.emailEmpty') })
        .email({ message: t('Modal.Register.errors.email') })
        .refine((value) => value.toLowerCase() !== creator.value?.email, {
          message: t('SectionReferral.errors.emailSelf'),
          path: ['email']
        })
        .refine((value) => !value.toLowerCase().includes('@yopmail') && !value.toLowerCase().includes('+'), {
          message: t('Modal.Register.errors.email'),
          path: ['email']
        })
    })
  )
  const { handleSubmit, errors, setErrors } = useForm({ validationSchema })
  const { value: email } = useField('email')

  const onSubmit = handleSubmit(async (values) => {
    useTrack().track('click-send-referral-email', { section: SectionName, email: values.email })

    try {
      await useStoreReferral().sendReferral({
        email: values.email,
        companyId: companyId as string
      })
      emit('success')
    } catch (error) {
      for (const value of Object.values(error as GenericApiError)) {
        const transKey = `ApiErrors.${value}`
        setErrors({ email: te(transKey) ? t(transKey) : t('ApiErrors.generic') })
      }
    }
  })
</script>

<template>
  <form
    class="referral-form"
    @submit="onSubmit"
  >
    <FormInputText
      v-model="email"
      name="email"
      :placeholder="$t('SectionReferral.friendEmail')"
      :error-message="errors.email"
    />
    <ButtonBranding
      :button-branding="branding"
      class="referral-form--submit"
      type="submit"
      @click="onSubmit"
    >
      {{ branding.text }}
    </ButtonBranding>
  </form>
</template>

<style scoped>
  .referral-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
</style>
